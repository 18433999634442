import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { TagColors } from '@/components/tag/tag.enums';
import { RnTag, RnButton, RnActionHeader, RnSpinner, RnNewTable, RnTabs, RnFilters, RnField } from '@/components';
import { batteriesMixin } from '@/mixins';
import RnPagination from '@/components/pagination/pagination.vue';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { formatAsDate } from '@/utils/format-as-date';
import { formatWeight } from '@/utils/units';

export default {
	name: 'rn-cp-collections-view',
	components: {
		RnPagination,
		RnButton,
		RnActionHeader,
		RnSpinner,
		RnNewTable,
		RnTabs,
		RnFilters,
		RnField,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			currentTabIndex: 0,
			ownerCollectionOrdersPage: 0,
			incomingUnplannedCollectionOrdersPage: 0,
			incomingPlannedCollectionOrdersPage: 0,
			visibleHeaders: [],
		};
	},
	computed: {
		...mapGetters([
			'ownerCollectionOrders',
			'ownerCollectionOrdersCount',
			'incomingUnplannedCollectionOrders',
			'incomingUnplannedCollectionOrdersCount',
			'incomingPlannedCollectionOrders',
			'incomingPlannedCollectionOrdersCount',
			'businessRelationId',
			'locations',
			'collectionOrderTransportPackagings',
		]),
		locationIds: () => (this.locations || []).map(location => location.id),
		collectionTableHeaders() {
			return [
				{
					text: this.$t('table.order.status'),
					value: 'status',
					component: RnTag,
					compact: true,
					componentProps: value => {
						if (!value.status) {
							return null;
						}

						return {
							text: this.$t(`table.order.status.${value.status}`),
							color: TagColors[value.status.toLowerCase()],
							remark: value.deliveryRemarks,
						};
					},
				},
				{
					text: this.$t('table.order.number'),
					value: 'number',
					compact: true,
				},
				{
					text: '',
					component: RnButton,
					sortable: false,
					renderCondition: (item) => this.renderPlanCollection(item) || this.renderUpdateCollection(item),
					componentProps: (item) => ({
						color: 'blue',
						method: () => {
							if (this.renderPlanCollection(item)) {
								this.planCollection(item.id);
							} else if (this.renderUpdateCollection(item)) {
								this.updateCollection(item.id);
							}
						},
						outline: true,
						disableWrap: true,
						size: 'x-small',
						text: this.renderPlanCollection(item) ? this.$t('common.planCollection') : this.$t('common.updateCollection'),
					}),
				},
				{
					text: '',
					component: RnButton,
					sortable: false,
					renderCondition: (item) => this.renderUpdateCollection(item),
					componentProps: (item) => ({
						color: 'blue',
						method: () => this.checkCollection(item.id),
						outline: true,
						disableWrap: true,
						size: 'x-small',
						text: this.$t('common.checkCollection'),
					}),
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('table.order.dateCollection'),
					value: 'pickUpDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('table.order.datePlanned'),
					value: 'plannedPickUpDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('table.order.brand'),
					value: 'batteries[0].batteryDescription.brand.name',
				},
				{
					text: this.$t('table.order.collectedBy'),
					value: 'owner.name',
				},
				{
					text: this.$t('common.pickupPoint'),
					value: 'sourceLocation.name',
				},
				{
					text: this.$t('common.totalWeight'),
					value: 'batteries',
					compact: true,
					customSort: (batteries) => (batteries || []).reduce((acc, battery) => acc + battery.nettoWeight, 0),
					display: (batteries) => {
						return formatWeight((batteries || []).reduce((acc, battery) => acc + battery.nettoWeight, 0));
					},
				},
			];
		},
		headerOptions() {
			return this.collectionTableHeaders.map(header => ({
				value: header.value,
				name: header.text,
			})).filter(header => !!header.value);
		},
		batteryTableHeaders() {
			return this.nestedBatteryTableHeaders;
		},
		tabs() {
			return [
				{
					label: this.$t('cp.collections.allOrders'),
					orders: this.ownerCollectionOrders,
					page: this.ownerCollectionOrdersPage,
					updatePage: this.updateOwnerCollectionOrders,
					totalItems: this.ownerCollectionOrdersCount,
				},
				{
					label: this.$t('cp.collections.incomingOrders'),
					orders: this.incomingUnplannedCollectionOrders,
					page: this.incomingUnplannedCollectionOrdersPage,
					updatePage: this.updateIncomingUnplannedCollectionOrders,
					totalItems: this.incomingUnplannedCollectionOrdersCount,
				},
				{
					label: this.$t('cp.collections.plannedTransportation'),
					orders: this.incomingPlannedCollectionOrders,
					page: this.incomingPlannedCollectionOrdersPage,
					updatePage: this.updateIncomingPlannedCollectionOrders,
					totalItems: this.incomingPlannedCollectionOrdersCount,
				},
			];
		},
		currentlyActiveTabInfo() {
			return get(this, `tabs[${this.currentTabIndex}]`);
		},
		collectionOrdersState() {
			if (!this.currentlyActiveTabInfo.orders) {
				return 'loading';
			}
			if (this.currentlyActiveTabInfo.orders.length) {
				return 'results';
			}
			return 'noResults';
		},
	},
	mounted() {
		const savedHeaders = window.localStorage.getItem("mfHeaders");
		this.visibleHeaders = JSON.parse(savedHeaders) || this.collectionTableHeaders.map(header => header.value);
	},
	updated() {
		window.localStorage.setItem("mfHeaders", JSON.stringify(this.visibleHeaders));
	},
	created() {
		this.updateOwnerCollectionOrders(0);
		this.updateIncomingUnplannedCollectionOrders(0);
		this.updateIncomingPlannedCollectionOrders(0);
	},
	methods: {
		renderButtonLabel(record) {
			switch (record.status) {
				case 'Planned':
					return this.$t('common.updateCollection');

				default:
					return this.$t('common.planCollection');
			}
		},
		renderPlanCollection(record) {
			switch (record.status) {
				case 'Planned':
				case 'Executed':
				case 'ExecutedWithRemarks':
					return false;
				default:
					return true;
			}
		},
		renderUpdateCollection(record) {
			if (record.status === 'Planned') {
				return true;
			}

			return false;
		},
		updateCurrentTabIndex(index) {
			this.currentTabIndex = index;
		},
		formatRemarks: collectionOrder => {
			const content = [];
			if (get(collectionOrder, 'sourceRemarks')) {
				content.push(`<b>${collectionOrder.sourceBusinessRelation.name}</b><br/>${collectionOrder.sourceRemarks}`);
			}
			if (get(collectionOrder, 'destinationRemarks')) {
				content.push(`<b>${collectionOrder.destinationBusinessRelation.name}</b><br/>${collectionOrder.destinationRemarks}`);
			}

			return content.join('<br/>');
		},
		updateCollectionOrders(page) {
			this.updateOwnerCollectionOrders(page);
			this.updateIncomingUnplannedCollectionOrders(page);
			this.updateIncomingPlannedCollectionOrders(page);
		},
		updateOwnerCollectionOrders(page) {
			this.ownerCollectionOrdersPage = page;
			this.$store.dispatch('getOwnerCollectionOrders', { businessRelationId: this.businessRelationId, page: page || 0, pageSize: this.ITEMS_PER_PAGE });
		},
		updateIncomingUnplannedCollectionOrders(page) {
			this.incomingUnplannedCollectionOrdersPage = page;
			this.$store.dispatch('getIncomingUnplannedCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
		updateIncomingPlannedCollectionOrders(page) {
			this.incomingPlannedCollectionOrdersPage = page;
			this.$store.dispatch('getIncomingPlannedCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
		planCollection(collectionOrderId) {
			this.$router.push({ name: 'CPNewCollection', params: { collectionOrderId } });
		},
		updateCollection(collectionOrderId) {
			this.$router.push({ name: 'CPUpdateCollection', params: { collectionOrderId } });
		},
		checkCollection(collectionOrderId) {
			this.$router.push({ name: 'CPOrderChecking', params: { id: collectionOrderId } });
		},
		updateHeaders(value) {
			this.visibleHeaders = value;
		},
	},
};
