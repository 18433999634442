import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { TagColors } from '@/components/tag/tag.enums';
import { RnTag, RnModal, RnButton, RnActionHeader, RnSpinner, RnPagination, RnAlert, RnNewTable, RnTabs, RnFilters } from '@/components';
import { batteriesMixin } from '@/mixins';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { formatAsDate } from '@/utils/format-as-date';
import { formatWeight } from '@/utils/units';

export default {
	name: 'rn-pup-collections-view',
	components: {
		RnButton,
		RnActionHeader,
		RnSpinner,
		RnPagination,
		RnAlert,
		RnNewTable,
		RnModal,
		RnTabs,
		RnFilters,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			currentTabIndex: 0,
			pendingCollectionOrdersPage: 0,
			checkedCollectionOrdersPage: 0,
			modal: {
				isOpen: false,
				data: null,
			},
		};
	},
	computed: {
		...mapGetters([
			'businessRelationId',
			'pupPendingCollectionOrders',
			'pupPendingCollectionOrdersCount',
			'pupCheckedCollectionOrders',
			'pupCheckedCollectionOrdersCount',
		]),
		brandName() {
			return get(this.record, 'batteries[0].batteryDescription.brand.name', '');
		},
		tableHeaders() {
			return [
				{
					text: this.$t('table.order.status'),
					value: 'status',
					component: RnTag,
					componentProps: value => ({
						text: this.$t(`table.order.status.${value.status}`),
						color: TagColors[value.status.toLowerCase()],
					}),
				},
				{
					text: this.$t('table.order.number'),
					value: 'number',
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					customSort: 'date',
					display: formatAsDate,
				},
				{
					text: this.$t('table.order.dateCollection'),
					value: 'pickUpDate',
					customSort: 'date',
					display: formatAsDate,
				},
				{
					text: this.$t('table.order.collectedBy'),
					value: 'owner.name',
				},
				{
					text: this.$t('common.totalWeight'),
					value: 'batteries',
					customSort: batteries => (batteries || []).reduce((acc, battery) => acc + Number(battery.nettoWeight), 0),
					display: batteries => formatWeight((batteries || []).reduce((acc, battery) => acc + Number(battery.nettoWeight), 0)),
				},
				{
					text: '',
					value: 'deliveryRemarks',
					renderCondition: item => !!item.deliveryRemarks,
					sortable: false,
					align: 'right',
					component: RnButton,
					componentProps: value => ({
						transparent: true,
						disableWrap: true,
						method: e => {
							e.stopPropagation();

							this.modal = {
								isOpen: true,
								data: value,
							};
						},
						icon: {
							name: 'comment',
							width: '16px',
							height: '16px',
						},
					}),
				},
			];
		},
		downloadBatteryHeaders() {
			return this.nestedBatteryTableHeaders;
		},
		steps() {
			return [
				{
					label: this.$t('pup.collections.pendingOrders'),
					orders: this.pupPendingCollectionOrders,
					page: this.pendingCollectionOrdersPage,
					updatePage: this.updatePendingCollectionOrders,
					totalItems: this.pupPendingCollectionOrdersCount,
				},
				{
					label: this.$t('pup.collections.checkedOrders'),
					orders: this.pupCheckedCollectionOrders,
					page: this.checkedCollectionOrdersPage,
					updatePage: this.updateCheckedCollectionOrders,
					totalItems: this.pupCheckedCollectionOrdersCount,
				},
			];
		},
		currentlyActiveTabInfo() {
			return this.steps[this.currentTabIndex];
		},
		collectionOrdersState() {
			if (!this.currentlyActiveTabInfo.orders) {
				return 'loading';
			}
			if (this.currentlyActiveTabInfo.orders.length) {
				return 'results';
			}
			return 'noResults';
		},
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
	},
	methods: {
		updateCollectionOrdersPaginated(page) {
			this.updatePendingCollectionOrders(page);
			this.updateCheckedCollectionOrders(page);
		},
		updateCurrentTabIndex(index) {
			this.currentTabIndex = index;
		},
		updatePendingCollectionOrders(page) {
			this.pendingCollectionOrdersPage = page;
			this.$store.dispatch('getPupPendingCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
		updateCheckedCollectionOrders(page) {
			this.checkedCollectionOrdersPage = page;
			this.$store.dispatch('getPupCheckedCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
	},
};
