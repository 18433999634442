import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { TagColors } from '@/components/tag/tag.enums';
import { batteriesMixin } from '@/mixins';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnHeader, RnButton, RnPagination, RnNewTable, RnOrderPlanner, RnSpinner, RnField, RnTag, RnFilters } from '@/components';
import { formatAsDate, formatAsMonthYear } from '@/utils/format-as-date';
import { formatWeight } from '@/utils/units';

export default {
	name: 'rn-mf-dashboard-view',
	components: {
		RnHeader,
		RnButton,
		RnPagination,
		RnNewTable,
		RnOrderPlanner,
		RnSpinner,
		RnField,
		RnTag,
		RnFilters,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
			visibleHeaders: [],
		};
	},
	computed: {
		...mapGetters(['userName', 'user', 'businessRelationId', 'brandCollectionOrders', 'brandCollectionOrdersCount', 'isLoading']),
		collectionOrdersState() {
			if (!this.brandCollectionOrders) {
				return 'loading';
			}
			if (this.brandCollectionOrders.length) {
				return 'results';
			}
			return 'noResults';
		},
		collectionTableHeaders() {
			return [
				{
					text: this.$t('table.order.status'),
					value: 'status',
					component: RnTag,
					compact: true,
					componentProps: value => {
						if (!value.status) {
							return null;
						}

						return {
							text: this.$t(`table.order.status.${value.status}`),
							color: TagColors[value.status.toLowerCase()],
							remark: value.deliveryRemarks,
						};
					},
				},
				{
					text: this.$t('table.order.number'),
					value: 'number',
					compact: true,
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('table.order.dateCollection'),
					value: 'pickUpDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('table.order.datePlanned'),
					value: 'plannedPickUpDate',
					customSort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('helpdesk.contactForm.regarding.reasons.2'),
					value: 'invoiceProposalDate',
					customSort: 'date',
					compact: true,
					display: formatAsMonthYear,
				},
				{
					text: this.$t('table.order.brand'),
					value: 'batteries[0].batteryDescription.brand.name',
				},
				{
					text: this.$t('table.order.collectedBy'),
					value: 'owner.name',
				},
				{
					text: this.$t('table.invoice.pickup'),
					value: 'sourceLocation.name',
				},
				{
					text: this.$t('common.totalWeight'),
					value: 'batteries',
					compact: true,
					customSort: (batteries) => (batteries || []).reduce((acc, battery) => acc + battery.nettoWeight, 0),
					display: (batteries) => {
						return formatWeight((batteries || []).reduce((acc, battery) => acc + battery.nettoWeight, 0));
					},
				},
			];
		},
		headerOptions() {
			return this.collectionTableHeaders.map(header => ({
				value: header.value,
				name: header.text,
			}));
		},
		batteryTableHeaders() {
			return this.nestedBatteryTableHeaders;
		},
		backgroundImage() {
			const uppercaseBusinessRelationId = this.businessRelationId.toUpperCase();
			switch (uppercaseBusinessRelationId) {
				case "FC20F17C-2B29-41DC-8E89-D2930222C9A6":
				case "CA200AF1-8794-4694-9017-D166414CDF62":
					return "zero-motorcycles-hero.jpg";

				default:
					return "mf-dash-header.jpg";
			}
		}
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
	},
	mounted() {
		const savedHeaders = window.localStorage.getItem("mfHeaders");
		this.visibleHeaders = JSON.parse(savedHeaders) || this.collectionTableHeaders.map(header => header.value);
	},
	updated() {
		window.localStorage.setItem("mfHeaders", JSON.stringify(this.visibleHeaders));
	},
	methods: {
		updateHeaders(value) {
			this.visibleHeaders = value;
		},
		updateCollectionOrdersPaginated(page, excel = false) {
			this.page = page;
			const filters = {
				businessRelationId: this.businessRelationId,
				// TODO this is a simplification, normally we would want the manufacturer to be able to see all the brands they have, not just the first brand
				brandId: get(this.user, 'businessRelation.brands[0].id'),
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
				excel,
			};

			this.$store.dispatch('getBrandCollectionOrders', filters);
		},
	},
};
